<template>
  <b-row>
    <b-col lg="6">
      <MainForm
        v-on:submit="submit" />
    </b-col>
  </b-row>
</template>
<script>
import MainForm from './form'

export default {
  name: 'roleCreate',
  created() {},
  methods: {
    submit() {
      const vm = this
      vm.$store.dispatch('role/STORE').then((res) => {
        vm.$notification.success({
          message: 'New Role Created',
          description: 'Successfully created.',
        })
        vm.$router.push({ path: '/roles' })
      })
    },
  },
  components: {
    MainForm,
  },
}
</script>
